$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * language
    // *
    // *
    $(".js-language-button").click(function(){
        $(this).parents(".js-language").toggleClass("active");
    });

    $(".js-language").mouseleave(function() {
        $(this).removeClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    $(window).on("load scroll", function(){
        if($(window).scrollTop() >= 30) {
			$(".js-header").addClass("sticky");
		}else {
			$(".js-header").removeClass("sticky");
		}
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * logoSlider
    // *
    var $logoSlider = $(".js-logoslider");

    if ($logoSlider.length) {
        $logoSlider.each(function(){
            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: false,
                autoplay: true,
                speed: 3000,
                autoplaySpeed: 0,
                waitForAnimate: true,
                draggable: false,
                touchMove: false,
                pauseOnFocus: false,
                pauseOnHover: false,
                responsive: [],
                rows: 0,
                variableWidth: true,
                cssEase: 'linear',
            });
        });
    }




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teaserSlider
    // *
    // *
    var $slider = $(".js-teaserslider");

    if ($slider.length) {
        $slider.each(function(){
            var $parent = $(this).parents(".js-teaserslider-wrapper"),
                total = $(this).data("total");

            $(this).slick({
                infinite: false,
                fade: true,
                dots: false,
                arrows: true,
                autoplay: false,
                speed: 1000,
                waitForAnimate: false,
                prevArrow: $parent.find(".js-teaserslider-nav-prev"),
                nextArrow: $parent.find(".js-teaserslider-nav-next"),
                responsive: [],
                rows: 0,
            });

            // set total
            $parent.find(".js-teaserslider-total").html(total);

            // set current count
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $parent.find(".js-teaserslider-current").html((nextSlide + 1));
            });

            // if currentslide = 0 ...
            $(this).on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $parent.find(".js-teaserslider-current").html((nextSlide + 1));
            });
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav mobile
    // *
    // *
    $(".js-nav-mobile-button").click(function () {
        $(this).toggleClass("active");
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    })

    $(".js-nav-mobile-toggle").click(function () {
        $(this).toggleClass("open");
        $(this).siblings().slideToggle();
    })

    $(".js-nav-mobile-toggle.active").trigger("click");





    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $imageTeaserSlider = $(".js-image-teaser-slider");

    var $imageTeaserSliderSettings = {
        fade: false,
        dots: true,
        infinite: false,
        arrows: false,
        autoplay: false,
        centerMode: true,
        speed: 1000,
        responsive: [
            {
                breakpoint: 99999,
                settings: "unslick"
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.05
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.1
                }
            }
        ]
    };

    // Init Slider
    $imageTeaserSlider.slick($imageTeaserSliderSettings);

    // Reslick only if it's not slick()
    $(window).on('resize', function() {
      if (!$imageTeaserSlider.hasClass('slick-initialized')) {
        return $imageTeaserSlider.slick($imageTeaserSliderSettings);
      }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function(){
        $(this).parents(".js-accordion").siblings().find(".js-accordion-item").removeClass("active").find(".js-accordion-content").slideUp();

        var $object = $(this);

        $object.parents(".js-accordion-item").toggleClass("active").find(".js-accordion-content").slideToggle(function(){
            $("html").animate({scrollTop: $object.offset().top - 160},800);
        }).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
    });




});
